import type { NextPage } from "next";
import React from "react";
import styled from "styled-components";

const Custom404: NextPage = () => {
  const StyledH1 = styled.h1`
    color: #dd4b39;
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  `;
  const StyledP = styled.p`
    color: #333;
    font-size: 1.5em;
    margin-bottom: 40px;
    text-align: center;
  `;
  return (
    <div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexFlow: "column wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            color: "#dd4b39",
            fontSize: "2em",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          페이지 오류 안내
        </h1>
        <p
          style={{
            color: "#333333",
            fontSize: "1.5em",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          이용에 불편을 드려 죄송합니다.
        </p>
        <p
          style={{
            color: "#333333",
            fontSize: "1.5em",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          학습지원센터(1588-5834) 문의 혹은 잠시 후에 이용해주십시오.
        </p>
      </div>
    </div>
  );
};

export default Custom404;
